import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { Params, ShowPageLoader, HidePageLoader } from "./CommonFunctions";

import App from "./App";
import { Error500, Error404 } from "./components/Errors/Errors";
import Clients from "./views/clients/Clients";
import Categories from "./views/categories/Categories";
import Projects from "./views/projects/Projects";
import ShowProjects, { ShowProjetLoader } from "./views/projects/ShowProjects";
import PreviewAsset, {
  PreviewProjetLoader,
} from "./views/projects/PreviewAsset";
import Assets from "./views/assets/Assets";
import Portfolios from "./views/portfolios/Portfolios";
import ShowPortfolio, {
  ShowPortfolioLoader,
} from "./views/portfolios/ShowPortfolio";
import PreviewPortfolio, {
  PreviewPortfolioLoader,
} from "./views/portfolios/PreviewPortfolio";
import ShareInsights from "./views/portfolios/ShareInsights";
import PortfolioShareList from "./views/portfolios/PortfolioShareList";
import LinkManagement from "./views/linkManagement/LinkManagement";
import LinkView from "./views/linkManagement/LinkView";
import UserManagement from "./views/userManagement/UserManagement";
import ClientError from "./ClientError";
const options = {
  basename: "/",
};

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />} errorElement={<Error500 />}>
      <Route
        index
        path="/"
        element={<Portfolios />}
        errorElement={<Error500 />}
      />
      <Route
        path="/categories/*"
        element={<Categories />}
        errorElement={<Error500 />}
      />
      <Route
        path="/projects/*"
        element={<Projects />}
        errorElement={<Error500 />}
      />
      <Route
        path="/links/*"
        element={<LinkManagement />}
        errorElement={<Error500 />}
      />
      <Route 
        path="/links/:link_id/view"
        element={<LinkView />}
        errorElement={<Error500 />}
      />
      <Route
        path="/users/*"
        element={<UserManagement />}
        errorElement={<Error500 />}
      />
      <Route
        path="/projects/show/*"
        element={<ShowProjects />}
        errorElement={<Error500 />}
        loader={async ({ params }: Params) => {
          ShowPageLoader();
          const data = await ShowProjetLoader({ params });
          HidePageLoader();
          return data;
        }}
      />
      <Route
        path="/projects/preview/*"
        element={<PreviewAsset />}
        errorElement={<Error500 />}
        loader={async ({ params }: Params) => {
          ShowPageLoader();
          const data = await PreviewProjetLoader({ params });
          HidePageLoader();
          return data;
        }}
      />
      <Route
        path="/portfolios/*"
        element={<Portfolios />}
        errorElement={<Error500 />}
      />
      <Route
        path="/portfolios/show/*"
        element={<ShowPortfolio />}
        errorElement={<Error500 />}
        loader={async ({ params }: Params) => {
          ShowPageLoader();
          const data = await ShowPortfolioLoader({ params });
          HidePageLoader();
          return data;
        }}
      />
      <Route
        path="/portfolios/preview/*"
        element={<PreviewPortfolio />}
        errorElement={<Error500 />}
        loader={async ({ params }: Params) => {
          ShowPageLoader();
          const data = await PreviewPortfolioLoader({ params });
          HidePageLoader();
          return data;
        }}
      />
      <Route
        path="/portfolios/projects/*"
        element={<PortfolioShareList />}
        errorElement={<Error500 />}
      />
      <Route
        path="/portfolios/shareInsights/*"
        element={<ShareInsights />}
        errorElement={<Error500 />}
      />
      <Route
        path="/clients/*"
        element={<Clients />}
        errorElement={<Error500 />}
      />
      <Route
        path="/assets/*"
        element={<Assets />}
        errorElement={<Error500 />}
      />
      <Route
        path="*"
        element={<ClientError />}
        errorElement={<Error500 />}
      ></Route>
    </Route>,
  ),
  options,
);

export default Router;
