import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Link } from "react-router-dom";
import Typography from "../Typography/Typography";
import ToastMessages from "../ToastMessage/Toast";
import Loader from "../Loader/Loader";
import Button from "../Button/Button";
import ecoLogo from "../../images/ecoLogo.svg";
import "./error.css";
import auditEmptyImage from "../../images/auditEmpty.svg";
import { error } from "console";
import clariantLog from "../../images/clarieniLogo.png";
import ErrorImage from "../../images/clientProject/error404.svg";
import SupportExpiredModal from "../../views/viewPortfolio/SupportExpiredModal";

export const Error404 = () => {
  return (
    // <div className="h-screen flex flex-col bg-gray-600 items-center">
    //   <div className="pt-12">
    //     <img
    //       src={ecoLogo}
    //       alt="logo"
    //       className="w-auto max-w-full h-10 object-cover object-left-top"
    //     />
    //   </div>
    //   <div className="h-full grid justify-items-center items-center">
    //     <section className="text-center">
    //       <Typography className="font-bold mb-2" variant="h2">
    //         Error 404
    //       </Typography>
    //       <div className="tip-wrapper">
    //         <Typography className="my-5" variant="h4">
    //           Page Not Found
    //         </Typography>
    //         <Button
    //           label="Refresh"
    //           variant="primary"
    //           type="button"
    //           size="large"
    //           onClick={()=>{window.location.href = "/"}}
    //         />
    //       </div>
    //     </section>
    //   </div>
    // </div>
    <div className="h-screen flex flex-col bg-gray-1400 items-center p-10 overflow-auto">
      <div className="">
        <img
          src={clariantLog}
          alt="logo"
          className="w-auto max-w-full h-14 object-cover object-left-top"
        />
      </div>
      <div className="text-center">
        <img
          src={ErrorImage}
          alt="error"
          className="w-[20.813rem] h-[20.813rem] m-auto"
        />
        <Typography
          variant="h1"
          className="font-bold text-900 text-pastel-900"
        >
          Oops! Something went wrong.
        </Typography>
        <Typography variant="h5" className="font-normal text-pastel-900">
          Don’t worry, our team is here to help
        </Typography>
        <button className="bg-secondary-400 text-500 font-medium text-white py-4 w-[27.5rem] mt-10 rounded-md hover:shadow-100 hover:bg-secondary-500"
          onClick={()=>{
            SupportExpiredModal.fire();
          }}
        >
          Request Support
        </button>
      </div>
    </div>
  );
};

export const ErrorExpired = () => {
  return (
    <div className="h-screen flex flex-col bg-gray-600 items-center">
      <div className="pt-12">
        <img
          src={ecoLogo}
          alt="logo"
          className="w-auto max-w-full h-10 object-cover object-left-top"
        />
      </div>
      <div className="h-full grid justify-items-center items-center">
        <section className="text-center">
          <Typography className="font-bold mb-2" variant="h2">
            Oops
          </Typography>
          <div className="tip-wrapper">
            <Typography className="my-5" variant="h4">
              Link is Expired
            </Typography>
            {/* <Button
              label="Refresh"
              variant="primary"
              type="button"
              size="large"
              onClick={()=>{window.location.href = "/"}}
            /> */}
          </div>
        </section>
      </div>
    </div>
  );
};

export const Error500 = () => {
  return (
    <div className="h-screen flex flex-col bg-gray-600 items-center">
      <div className="pt-12">
        <img
          src={ecoLogo}
          alt="logo"
          className="w-auto max-w-full h-10 object-cover object-left-top"
        />
      </div>
      <div className="h-full grid justify-items-center items-center">
        <section className="text-center">
          <Typography className="font-bold mb-2" variant="h2">
            Error 500
          </Typography>
          <div className="tip-wrapper">
            <Typography className="my-5" variant="h4">
              Something went wrong!
            </Typography>
            <Button
              label="Refresh"
              variant="primary"
              type="button"
              size="large"
              onClick={()=>{
                SupportExpiredModal.fire();
              }}
              // onClick={() => window.location.reload()}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export const ErrorAPI404 = () => {
  // console.log("error");
  return (
    <>
      <div className="mt-32 text-center flex justify-center items-center w-full">
        <div>
          <img
            src={auditEmptyImage}
            alt="auditEmptyImage"
            className="w-[10.25rem] h-[10.25rem] object-cover"
          />
          <Typography variant="h6" className="text-gray-700 mt-2.5 !text-400">
            No Data Found
          </Typography>
        </div>
      </div>
    </>
  );
};

interface EmptyProps {
  heading: string;
  subHeading?: string;
  btnTitle?: string;
  link?: string;
}

export const EmptyData = ({
  heading,
  subHeading,
  btnTitle,
  link,
}: EmptyProps) => {
  return (
    <>
      <section className="empty-main">
        <div className="EmptyDatadiv">
          <Typography className="py-2" variant="h1">
            {heading}
          </Typography>
          {subHeading && (
            <Typography className="py-2" variant="h3">
              {subHeading}
            </Typography>
          )}
          {btnTitle && (
            <Link to={link}>
              <Button label={btnTitle} variant="secondary" size="large" />
            </Link>
          )}
        </div>
      </section>
    </>
  );
};

export const ErrorAPI500 = () => {
  return (
    <>
      <div className="mt-20 text-center">
        <Typography variant="h5">Something went wrong!</Typography>
      </div>
    </>
  );
};

type ToastType = "info" | "success" | "warning" | "error";

interface ShowToastProps {
  variant: ToastType;
  message: string;
}

export class ShowToast extends React.Component<ShowToastProps> {
  static fire(props: ShowToastProps) {
    const random = Math.random();
    const container = document.createElement("div");
    container.id =
      "custom-alert-container-" +
      props.message.split(" ").join("_") +
      "_" +
      random;
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    setTimeout(() => {
      close();
    }, 7000);

    root.render(
      <ToastMessages messages={props.message} type={props.variant} />
    );
  }

  render() {
    return null;
  }
}

export class ShowLoader extends React.Component {
  static fire() {
    const container = document.createElement("div");
    container.id = "custom-loader-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    root.render(<Loader />);
  }

  static close() {
    const container = document.getElementById("custom-loader-container");
    if (container !== undefined && container !== null) {
      const root = createRoot(container);
      root.unmount();
      container.remove();
    }

    return false;
  }

  render() {
    return null;
  }
}
