import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { changeFileHandler,inputChangeHandler } from "../../CommonFunctions";
import Button from "../../components/Button/Button";
import CustomField from "../../components/Formik/CustomField";
import CustomTextArea from "../../components/Formik/CustomTextArea";
import CustomUploadField from "../../components/Formik/CustomUploadField";
import Spinner from "../../components/Spinner/Spinner";
import { InputFiled, saveFiles, validationSchema } from "./AddFilesFunction";
import CustomLoader from "../../components/Loader/CustomLoader";
import TagInput from "../../components/TagInput/TagInput";

const AddFiles = (props: any) => {
  const loadData = props.loadData;
  const setAsset = props.setAsset;
  const setIsLoading = props.setIsLoading;
  const isLoading = props.isLoading;
  const asset = props.asset;

  const [isFromSubmiting, setIsFromSubmiting] = useState(false);
  const [isUploadShow, setIsUploadShow] = useState(true);
  const [isUpload, setIsUpload] = useState(false);
  const form = useRef(null);

  const FormData: InputFiled = {
    filename:
      asset !== undefined && asset !== null && asset.filename !== undefined
        ? [asset.filename]
        : "",
    file: [],
    source:
      asset !== undefined && asset !== null && asset.source !== undefined
        ? asset.source
        : "",
    description:
      asset !== undefined && asset !== null && asset.description !== undefined
        ? asset.description
        : "",
    file_type: "file",
    tags: asset !== undefined && asset !== null && asset.tags !== undefined ? asset.tags : "",
  };

  // const submitHandler = async (
  //   values: InputFiled,
  //   { setErrors, resetForm }: any,
  // ) => {
  //   debugger;
  //   await saveFiles({
  //     setIsFromSubmiting: setIsFromSubmiting,
  //     setErrorMessage: setErrors,
  //     resetForm: resetForm,
  //     form: form,
  //     asset: asset,
  //     loadData: loadData,
  //     setAsset: setAsset,
  //     setIsLoading: setIsLoading,
  //   });
  // };

  // const submitHandler = async (
  //   values: InputFiled, 
  //   { setErrors, resetForm }: any
  // ) => {
  //   console.log(values, "values");
  //   if (!values.file || values.file.length === 0) {
  //     console.warn("No files to submit.");
  //     return;
  //   }
  
  //   await saveFiles({
  //     setIsFromSubmiting: setIsFromSubmiting,
  //     setErrorMessage: setErrors,
  //     resetForm: resetForm,
  //     form: form, 
  //     asset: asset,
  //     loadData: loadData,
  //     setAsset: setAsset,
  //     setIsLoading: setIsLoading,
  //     files: values.file, 
  //     // assetDto: {
  //     //   filename: values.filename.join(','),
  //     //   file_type: values.file_type,
  //     //   source: values.source,
  //     //   description: values.description,
  //     //   tags:values.tags,
  //     // },
  //   });
  // };
  const submitHandler = async (
  values: InputFiled,
  { setErrors, resetForm }: any
) => {
  if (values.file && values.file?.length > 1 || values.filename?.length > 1) {
    await saveFiles({
      setIsFromSubmiting: setIsFromSubmiting,
      setErrorMessage: setErrors,
      resetForm: resetForm,
      form: form,
      asset: asset,
      loadData: loadData,
      setAsset: setAsset,
      setIsLoading: setIsLoading,
      files: values.file, 
      assetDto: {
        filename: values.filename,
        file_type: values.file_type,
        source: values.source,
        description: values.description,
       tags:values.tags,
      },
    });
  } else {
    await saveFiles({
      setIsFromSubmiting: setIsFromSubmiting,
      setErrorMessage: setErrors,
      resetForm: resetForm,
      form: form,
      asset: asset,
      loadData: loadData,
      setAsset: setAsset,
      setIsLoading: setIsLoading,
      files: values.file, 
      assetDto: {
        filename: values.filename,
        //file_type: values.file_type,
        source: values.source,
        description: values.description,
       tags:values.tags,
      },
    });
  }
};

  
  useEffect(()=>{
    setIsUploadShow(asset !== undefined && asset !== null?false:true)
  },[asset]);

  useEffect(()=>{
    setIsUpload(!isUploadShow);
  },[isUploadShow])

  return (
    <>
      {isLoading?(
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <CustomLoader/>
      </div>
        // <div className="animate-pulse">
        //   <div className="flex-1 space-y-6">
        //     <div className="h-32 bg-gray-100 rounded" />
        //     <div className="h-10 bg-gray-100 rounded" />
        //     <div className="h-32 bg-gray-100 rounded" />
        //   </div>
        //   <div className="flex mt-5 justify-start gap-2.5">
        //     <div className="h-14 w-40 bg-gray-100 rounded" />
        //     <div className="h-14 w-40 bg-gray-100 rounded" />
        //   </div>
        // </div>
      ):(
        <Formik
          initialValues={FormData}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ setValues, values }) =>   (
           
            <Form ref={form}>
              <div className="hide">
                <Field
                  label="File Type"
                  name="file_type"
                  component={CustomField}
                  type="hidden"
                  placeholder="Enter File Type"
                  defaultValue="file"
                />
                <ErrorMessage
                  name="file_type"
                  component="div"
                  className="error text-error text-300 mt-1"
                />
              </div>
              {asset !== undefined &&
                asset !== null &&
                asset.url !== undefined && 
                isUploadShow === false
                ? (
                <div className="h-[18rem] w-full bg-white rounded-md flex justify-center items-center overflow-hidden 
                    text-center border border-gray-400 relative">
                  {asset.type === "Images"?(
                    <img src={asset["url"]} alt={asset["filename"]} className="w-full h-full object-cover" />
                  ):(
                    <iframe
                      className="w-full h-full"
                      src={asset["url"]}
                    ></iframe>
                  )}
                  <div className="absolute right-4 top-4 flex justify-end items-start gap-2.5">
                      <a href={asset["url"]} target="_blank" rel="noopener noreferrer" className="bg-white/[.7] p-1 rounded-sm relative group" download>
                        <svg width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 11V17L11 15" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M9 17L7 15" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="absolute -top-4 right-0 text-[8px] text-gray-300 font-medium bg-white p-0.5 w-[74px] rounded-full hidden group-hover:inline-block">
                          {asset.file_type === "Images"?
                            "Download image"
                          :asset.file_type === "Videos"?
                            "Download video"
                          :
                            "Download document"
                          }
                        </span>
                      </a >
                      <button 
                        className="bg-white/[.7] p-1 rounded-sm relative group" 
                        onClick={()=>{
                          setIsUploadShow(true);
                          inputChangeHandler("", {
                            setValues: setValues,
                            key: "filename",
                            formData: values,
                          });
                        }}
                      >
                        <svg width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 3.11 16.44 3.11 16.44M3.11 
                          16.44H7.63M3.11 16.44V21.44M2 12C2 6.48 6.44 2 12 2C18.67 2 22 7.56 22 7.56M22 
                          7.56V2.56M22 7.56H17.56" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        <span className="absolute -top-4 right-0 text-[8px] text-gray-300 font-medium bg-white p-0.5 w-[74px] rounded-full hidden group-hover:inline-block">
                          {asset.file_type === "Images"?
                            "Replace image"
                          :asset.file_type === "Videos"?
                            "Replace video"
                          :
                            "Replace document"
                          }
                        </span>
                      </button>
                  </div>
                </div>
              ): ""}
              {isUploadShow?(
                <div className="">
                  <Field
                    label="Browse Files"
                    name="file"
                    id="file"
                    className="w-full file_parent"
                    component={CustomUploadField}
                    accept=".pdf,.doc,.docx,.csv,.jpeg,.jpg,.png,.mp4,.avi,.wmv"
                    multiple={true}
                    onFileChange={(files: FileList | null) => {
                      changeFileHandler(files, {
                        setValues: setValues,
                        key: "filename",
                        formData: values,
                      });
                    }}
                    // multiple={asset !== undefined && asset !== null ? false : true}
                    // onFileChange={(files: any) => {
                    //   debugger;
                    //   changeFileHandler(files, {
                    //     setValues: setValues,
                    //     key: "filename",
                    //     formData: values,
                    //   });
                    //   setIsUpload(files !== null && files.length > 0);
                      // if(files!== null && files.length > 0){
                      //   setIsUpload(true);
                      // }else{
                      //   setIsUpload(false);
                      // }
                    // }}
                    style={{
                      background: "#fff",
                    }}
                  />
                  <ErrorMessage
                    name="filename"
                    component="div"
                    className="error text-error text-300 mt-1"
                  />
                </div>
              ):""}
              <div className="hidden">
                <Field
                  label="File Name"
                  name="filename"
                  component={CustomField}
                  type="text"
                  // placeholder="Enter File Name"
                  defaultValue={
                    asset !== undefined &&
                    asset !== null &&
                    asset.filename !== undefined
                      ? asset["filename"]
                      : ""
                  }
                />
              </div>
              {values.file && (values.file.length === 0 || values.file.length === 1) && (
              <>
              <div className="mt-6">
              <Field
              label="Source"
              name="source"
              component={CustomField}
              type="text"
              placeholder="Enter file source"
              defaultValue={
              asset !== undefined &&
              asset !== null &&
              asset.source !== undefined
              ? asset["source"]
              : ""
              }
              />
              <ErrorMessage
              name="source"
              component="div"
              className="error text-error text-300 mt-1"
              />
              </div>
              <div className="mt-6">
              <Field
              label="File Description"
              name="description"
              resize
              component={CustomTextArea}
              type="text"
              placeholder="Enter file description"
              defaultValue={
              asset !== undefined &&
              asset !== null &&
              asset.description !== undefined
              ? asset["description"]
              : ""
              }
              />
              <ErrorMessage
              name="description"
              component="div"
              className="error text-error text-300 mt-1"
              />
              </div>
              <div className="mt-6">
                <label className="text-400 font-medium text-gray-300 block mb-2">
                  Tags
                </label>
                <TagInput
                  placeholder="Type & enter"
                  value={
                    values["tags"] !== "" && values["tags"] !== null
                      ? values["tags"].split(",")
                      : []
                  }
                  onTagsChange={(tags) => {
                    let tag = "";
                    for (const i in tags) {
                      const newTag = tags[i];
                      tag =
                        tag.trim() !== null && tag.trim() !== ""
                          ? tag + ","
                          : tag;
                      tag = tag + newTag;
                    }
                    inputChangeHandler(tag, {
                      setValues: setValues,
                      key: "tags",
                      formData: values,
                    });
                  }}
                />
                <ErrorMessage
                  name="tags"
                  component="div"
                  className="error text-error text-200 mt-1"
                />
              </div>
              </>
              )}          
              <div className="mt-5 flex justify-start gap-2.5">
                {/* In Edit screen Button text will be SAVE */}
                <Button
                  type="submit"
                  variant="primary"
                  isDisabled={isFromSubmiting}
                  label={
                    isFromSubmiting ? <Spinner labal="Uploading..." /> :isUploadShow? "Upload":"Update"
                  }
                  size="large"
                />
                <Button
                  type="button"
                  variant="outline"
                  label="Cancel"
                  size="large"
                  isDisabled={!isUpload}
                  onClick={() => {
                    setIsLoading(true);
                    setIsFromSubmiting(false);
                    setIsUpload(false);
                    if (asset === null) {
                      setAsset(undefined);
                    } else {
                      setAsset(null);
                    }
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddFiles;
