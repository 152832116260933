import React, { useState } from "react";
import Slider from "react-slick";
import Typography from "../../components/Typography/Typography";
import styled from 'styled-components';

export const AssetSliderStyle = styled.div`
    .slide2 .slick-slide {
        width: 6rem !important;
        height: 5rem !important;
        margin: 0 0.75rem 0.75rem 0;
        border-radius: 6px;
        border:1px solid #D9D9D9;
        overflow:hidden;
    }
    .slide2 .slick-slide.slick-current{
        background: #fff;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    }
    .slide2{
      padding:0px 50px
    }
    .slide1 .slick-slide{
        transition: transform 0.5s ease;
    }
    .slider1 .slick-prev{
        height: 3rem !important;
        width: 3rem !important;
    }
    .slider1 .slick-next {
        height: 3rem !important;
        width: 3rem !important;
    }
    .slider1 .slick-prev:before{
        font-size: 32px !important;
        color: #646464 !important;
    }
    .slider1 .slick-next:before {
        font-size: 32px !important;
        color: #646464 !important;
    }
`;

export default function AssetSlider(prop: any) {
  const projectAssets = prop.projectAssets;
  const [nav1, setNav1] = useState<HTMLElement>();
  const [nav2, setNav2] = useState<HTMLElement>();

  return (
    <>
      {projectAssets !== null && projectAssets !== undefined ? (
        <AssetSliderStyle>
          <div>
            <div className="slider1">
            <Slider
              asNavFor={nav2}
              ref={(slider1: HTMLElement) => setNav1(slider1)}
              centerMode={true}
              slidesToShow={1}
              slidesToScroll={1}
              fade={true}
              dots={false}
              arrows={true}

            >
              {projectAssets !== null && projectAssets !== undefined
                ? projectAssets.map((item: any, idx: any) => (
                    <div key={idx}>
                      <div className="h-[calc(100vh-14.25rem)] overflow-hidden flex justify-center items-center">
                        {item.type === "Images" ? (
                          <img
                            src={item.url}
                            alt={item.name}
                            className="max-w-full max-h-full object-cover"
                          />
                        ) : item.type === "Documents" ? (
                          <iframe
                            className="w-full h-full"
                            src={item.url}
                          ></iframe>
                        ) : item.type === "Videos" ? (
                          <iframe
                            className="w-full h-full"
                            src={item.url}
                          ></iframe>
                        ) : item.type === "Links" ? (
                          <iframe
                            className="w-full h-full"
                            src={item.url}
                          ></iframe>
                        ) : (
                          ""
                        )}
                      </div>
                      {item.caption !== null && item.caption !== "" ? (
                        <Typography
                          className="text-center mt-1.5 text-secondary-200 font-medium"
                          size="md"
                          variant="p"
                        >
                          {item.caption}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
                : ""}
            </Slider>
            </div>
           <div className="slide2 mt-8">
            <Slider
              asNavFor={nav1}
              ref={(slider2: HTMLElement) => setNav2(slider2)}
              slidesToShow={projectAssets.length}
              swipeToSlide={true}
              focusOnSelect={true}
            >
              {projectAssets !== null && projectAssets !== undefined
                ? projectAssets.map((item: any, idx: any) => (
                    <div
                      key={idx}
                      className="!flex justify-center items-center h-[5rem] w-full rounded-md cursor-pointer p-1"
                    >
                      {item.type === "Images" ? (
                        <img
                          src={item.url}
                          alt={item.url}
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div className="text-center">
                          {item.type === "Documents" ? (
                            <span className="flex justify-center items-center mb-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.75 9H8.25"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.75 15H8.25"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          ) : item.type === "Videos" ? (
                            <span className="flex justify-center items-center mb-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 11.9999V8.43989C4 4.01989 7.13 2.2099 10.96 4.4199L14.05 6.1999L17.14 7.9799C20.97 10.1899 20.97 13.8099 17.14 16.0199L14.05 17.7999L10.96 19.5799C7.13 21.7899 4 19.9799 4 15.5599V11.9999Z"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          ) : item.type === "Links" ? (
                            <span className="flex justify-center items-center mb-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.5 12C13.5 15.18 10.93 17.75 7.75 17.75C4.57 17.75 2 15.18 2 12C2 8.82 4.57 6.25 7.75 6.25"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10 12C10 8.69 12.69 6 16 6C19.31 6 22 8.69 22 12C22 15.31 19.31 18 16 18"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          ) : (
                            ""
                          )}
                          <Typography
                            className="text-seondary-200 font-medium line-clamp-1"
                            size="xs"
                            variant="p"
                          >
                            {item.name}
                          </Typography>
                        </div>
                      )}
                    </div>
                  ))
                : ""}
            </Slider>
           </div>
          </div>
        </AssetSliderStyle>
      ) : (
        <div className="mt-20 text-center">
          <Typography variant="h5">No Assets found</Typography>
        </div>
      )}
    </>
  );
}
