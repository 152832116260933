import * as Yup from "yup";
import { showToastAlert, callFetch } from "../../CommonFunctions";

const controller = new AbortController();

export interface InputFiled {
  filename: any;
  file_type: any;
  source?: string;
  description?: string;
  file?: [];
  tags: any;
}

export const ErrorMessages: InputFiled = {
  filename: "",
  source: "",
  description: "",
  file_type: "",
  tags:[],
};

export const validationSchema = Yup.object({
  filename: Yup.array().required("File is required").test('fileFormat', 'Only image, video and pdf files are allowed', value => {
    if (value !== undefined && value) {
      for(const i in value){
        const fileData = value[i].split('.');
        const fileExtantion = String(fileData[fileData.length-1]);
        const supportedFormats = [
          'jpeg',
          'jpg',
          'png',
          'pdf',
          'doc',
          'docx',
          'csv',
          'mp4',
          'avi',
          'wmv',
        ];
        return supportedFormats.includes(fileExtantion);
      }
    }
    return true;
  }),
  // source: Yup.string()
  //   .required("File name is required")
  //   .min(2, "Too Short!")
  //   .max(254, "Too Long!"),
});


// export const saveFiles = async (props: any) => {
//   debugger;
//   const errorMessage = ErrorMessages;
//   props.setIsFromSubmiting(true);

//   // const formData = new FormData(props.form.current);
//   const formData = new FormData();

//   const files = props.files; 
//   for (let i = 0; i < files.length; i++) {
//     formData.append('files', files[i]); 
//   }

//   const assetDto = props.assetDto;
//   for (const key in assetDto) {
//     if (assetDto.hasOwnProperty(key)) {
//       formData.append(key, assetDto[key]);
//     }
//   }
//   console.log(formData, "props.form.current")
//   let url = process.env.REACT_APP_API_URL + "assets";
//   url =
//     props.asset !== undefined &&
//     props.asset !== null &&
//     props.asset.id !== undefined
//       ? url + "/" + props.asset.id
//       : url;

//   return await callFetch({
//     url: url,
//     method: "POST",
//     body: formData,
//     controller: controller,
//     headers: {},
//     callback: async (json: any) => {
//       props.setIsFromSubmiting(false);

//       if (json.status === "success") {
//         showToastAlert(json.message, 1);
//         props.setIsLoading(true);
//         if (props.asset === undefined) {
//           props.setAsset(null);
//         } else {
//           props.setAsset(undefined);
//         }
//         props.resetForm();
//         props.loadData();
//       } else if (
//         json.status === "error" &&
//         json.error === "validation_errors"
//       ) {
//         for (const name in json.validation_errors) {
//           if (name === "file") {
//             errorMessage["filename"] = json.validation_errors[name][0];
//           } else {
//             errorMessage[name as keyof InputFiled] =
//               json.validation_errors[name][0];
//           }
//         }

//         props.setErrorMessage(errorMessage);
//       } else {
//         showToastAlert(json.message);
//       }

//       return json;
//     },
//   });
// };

export const saveFiles = async (props: any) => {
  const errorMessage = ErrorMessages;
  props.setIsFromSubmiting(true);

  const formData = new FormData();
  const files = props.files;


  if (files.length === 1) {

    formData.append('file', files[0]);
  } else {

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
  }

  const assetDto = props.assetDto;
  for (const key in assetDto) {
    if (assetDto.hasOwnProperty(key)) {
      formData.append(key, assetDto[key]);
    }
  }


  let url = process.env.REACT_APP_API_URL + "assets";
  url =
    props.asset !== undefined &&
    props.asset !== null &&
    props.asset.id !== undefined
      ? url + "/" + props.asset.id
      : url;

  if (files.length > 1) {
    url = process.env.REACT_APP_API_URL + "assets/multiple";
  }

  return await callFetch({
    url: url,
    method: "POST",
    body: formData,
    controller: controller,
    headers: {},
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);

      if (json.status === "success") {
        showToastAlert(json.message, 1);
       props.setIsLoading(true);
       if (props.asset === undefined) {
                  props.setAsset(null);
                } else {
                  props.setAsset(undefined);
                }
        props.resetForm();
        props.loadData();
      } else if (json.status === "error" && json.error === "validation_errors") {
        for (const name in json.validation_errors) {
          if (name === "file") {
            errorMessage["filename"] = json.validation_errors[name][0];
          } else {
            errorMessage[name as keyof InputFiled] = json.validation_errors[name][0];
          }
        }
        props.setErrorMessage(errorMessage);
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};


