import React from "react";
import clariantLog from "../src/images/clarieniLogo.png";
import ErrorImage from "../src/images/clientProject/error404.svg";
import Typography from "./components/Typography/Typography";
import SupportExpiredModal from "./views/viewPortfolio/SupportExpiredModal";

const ClientError = () => {
  return (
    <div className="h-screen flex flex-col bg-gray-1400 items-center p-10 overflow-auto">
      <div className="">
        <img
          src={clariantLog}
          alt="logo"
          className="w-auto max-w-full h-14 object-cover object-left-top"
        />
      </div>
      <div className="text-center">
        <img
          src={ErrorImage}
          alt="error"
          className="w-[20.813rem] h-[20.813rem] m-auto"
        />
        <Typography variant="h1" className="font-bold text-900 text-pastel-900">
          Oops! Something went wrong.
        </Typography>
        <Typography variant="h5" className="font-normal text-pastel-900">
          Don’t worry, our team is here to help
        </Typography>
        <button
          className="bg-secondary-400 text-500 font-medium text-white py-4 w-[27.5rem] mt-10 rounded-md hover:shadow-100 hover:bg-secondary-500"
          onClick={()=>{
            SupportExpiredModal.fire();
          }}
        >
          Request Support
        </button>
      </div>
    </div>
  );
};

export default ClientError;
