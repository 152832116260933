import React, { ChangeEvent, useRef, useState } from "react";
import FileIcon from "../FileIcon/FileIcon";
import Icons from "../Icons/Icon";

export interface FileUploadProps {
  label?: string;
  className?: string;
  style?: React.CSSProperties;
  onFileChange: (files: FileList | null) => void;
  multiple?: boolean;
  name?: string;
  id?: string;
  accept?:string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  label,
  className,
  style,
  onFileChange,
  multiple = false,
  name,
  id,
  accept,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setSelectedFiles(files);

    if (files) {
      const fileNames = Array.from(files).map((file) => file.name);
      setSelectedFileNames(fileNames);
    } else {
      setSelectedFileNames([]);
    }

    onFileChange(files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const files = event.dataTransfer.files;
    setSelectedFiles(files);

    if (files) {
      const fileNames = Array.from(files).map((file) => file.name);
      setSelectedFileNames(fileNames);
    } else {
      setSelectedFileNames([]);
    }

    onFileChange(files);
  };

  // const removeFile = (index: number) => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = '';
  //   }
  //   const newSelectedFiles = Array.from(selectedFiles || []);
  //   newSelectedFiles.splice(index, 1);

  //   const dataTransfer = new DataTransfer();
  //   newSelectedFiles.forEach((file) => {
  //     dataTransfer.items.add(file);
  //   });

  //   setSelectedFiles(dataTransfer.files.length > 0 ? dataTransfer.files : null);

  //   const newFileNames = Array.from(selectedFileNames);
  //   newFileNames.splice(index, 1);
  //   setSelectedFileNames(newFileNames);

  //   onFileChange(dataTransfer.files.length > 0 ? dataTransfer.files : null);
  // };
  const removeFile = (index: number) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const newSelectedFiles = Array.from(selectedFiles);
      newSelectedFiles.splice(index, 1);

      const dataTransfer = new DataTransfer();
      newSelectedFiles.forEach((file) => {
        dataTransfer.items.add(file);
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = ''; 
      }

      setSelectedFiles(dataTransfer.files.length > 0 ? dataTransfer.files : null);

      const newFileNames = Array.from(selectedFileNames);
      newFileNames.splice(index, 1);
      setSelectedFileNames(newFileNames);

      onFileChange(dataTransfer.files.length > 0 ? dataTransfer.files : null);
    }
  };
  
  return (
    <div className={`${className ? className : ""}`}>
      <label
        className={`relative border border-dashed border-primary-100 rounded-md flex flex-col gap-3 p-5 items-center cursor-pointer transition-all ${
          isDraggingOver ? "!bg-gray-200 shadow-100" : ""
        }`}
        style={style}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          ref={fileInputRef}
          type="file"
          className="sr-only"
          onChange={handleFileChange}
          accept={accept}
          multiple={multiple}
          name={name}
          id={id}
        />
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="23.9442" cy="24" rx="23.9442" ry="24" fill="#F8F8F8" />
          <path
            d="M30.0773 34.6666C31.8598 34.6799 33.5758 34.0133 34.8928 32.8133C39.2426 28.9999 36.9147 21.3466 31.1814 20.6266C29.1329 8.17326 11.2146 12.8933 15.4581 24.7466"
            stroke="#F15845"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.6655 25.2928C16.9604 24.9328 16.1756 24.7461 15.3908 24.7595C9.19187 25.1995 9.20518 34.2395 15.3908 34.6795"
            stroke="#F15845"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.0253 21.1863C29.717 20.8396 30.4619 20.653 31.2335 20.6396"
            stroke="#F15845"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.561 28.8408L23.944 23.4453L29.327 28.8408"
            stroke="#F15845"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.944 38.5557V23.5957"
            stroke="#F15845"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="text-400 font-bold text-secondary-100">{label}</span>
      </label>

      {selectedFiles && selectedFileNames.length > 0 ? (
        <div className="flex flex-col mt-5">
          {selectedFileNames.map((fileName, index) => (
            <div
              key={index}
              className="flex items-center justify-between py-3 px-4 border border-gray-100 mt-2 rounded"
            >
              <span className="w-6">
                <FileIcon fileName={fileName} style={{ fontSize: "1.25rem" }} />
              </span>
              <span className="ml-2 text-400 font-bold text-secondary-100 grow overflow-hidden text-ellipsis whitespace-nowrap">
                {fileName}
              </span>
              <button
                type="button"
                onClick={() => removeFile(index)}
                className="ml-2"
              >
                <Icons size={12} name="cross" variant="stroke" />
              </button>
            </div>
          ))}
        </div>
      ) : null}
      {/* {selectedFiles && selectedFileNames.length > 1 && ( 
      <div className="text-200 text-gray-300 mt-4 flex justify-start items-center gap-1">
        <Icons
      name="info-circle"
      variant="bold"
      className=""
      size={14}
      color="#888"
    />
    <div>The source and description will apply to all uploaded files. For different files, upload separately.</div>
    </div>
    )} */}
    </div>
  );
};

export default FileUpload;
