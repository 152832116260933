import React, {useState} from "react";
import { useCookies } from 'react-cookie'
import { Error500,Error404 } from "./components/Errors/Errors";
import {
  FetchData,
  authenticateToken,
  dologin
} from "./CommonFunctions";
import Loader from "./components/Loader/Loader";
import store from "./store";
import { ClientTokenUpdate,ClientDetailsUpdate } from "./ClientToken";
import CheckPassword from "./CheckPassword";
import ErrorExpired from "./views/viewPortfolio/ErrorExpired";
import ErrorRevok from "./views/viewPortfolio/ErrorRevok";
import ClientError from "./ClientError";

interface ClientAuthenticateProps {
  children: React.ReactNode;
}

export const ClientAuthenticate = ({ children }: ClientAuthenticateProps) => {
  const [isLoading,setIsLoading] = useState<boolean>(false);
  const [isPassword,setIsPassword] = useState<boolean>(false);
  const [authenticateData,setAuthenticateData] = useState<FetchData>(); 
  
  let accessToken = ""; 

  const [cookies, setCookie] = useCookies(['clientaccess_token','acp','clientlink_token']);

  const location = window.location.pathname
  const pathname = location.split("/");

  const doLogin = async (linkToken:string,isReload:boolean = true) => {
    if(isLoading === false){
      setIsLoading(true);
      
      const fetchData:FetchData = await dologin(linkToken);
      if (fetchData.status === "error" && (fetchData.error === "abort"||fetchData.error === 404)) {
        setAuthenticateData({"status":"error", "error":404});
        return false;
      }

      if(fetchData["status"] === "success"){
        let expires = new Date()
        expires.setTime(expires.getTime() + (1*24*60*60*1000))
        setCookie('clientlink_token', linkToken, { path: '/',  expires});

        expires = new Date()
        expires.setTime(expires.getTime() + (1*60*60*1000))
        setCookie('clientaccess_token', fetchData["data"].access_token, { path: '/',  expires});

        if(isReload){
          window.location.href = "/client/welcomeClient";
        }else{
          store.dispatch(
            ClientTokenUpdate({
              token: fetchData["data"].access_token,
              linkToken:linkToken
            })
          );
          accessToken = fetchData["data"].access_token;

          return true;
        }
      }else{
        let expires = new Date()
        expires.setTime(expires.getTime() + (1*24*60*60*1000))
        setCookie('clientlink_token', linkToken, { path: '/',  expires});
        
        setAuthenticateData(fetchData);
      }

      return false;
    }
  }

  if(pathname[2] === "authenticate"){
    doLogin(pathname[3],true);
  }else{
    const linkToken = cookies["clientlink_token"] !== undefined && cookies["clientlink_token"] !== "" && cookies["clientlink_token"] !== null?cookies["clientlink_token"]:"";
    accessToken = cookies["clientaccess_token"] !== undefined && cookies["clientaccess_token"] !== "" && cookies["clientaccess_token"] !== null?cookies["clientaccess_token"]:"";

    store.dispatch(
      ClientTokenUpdate({
        token: accessToken,
        linkToken:linkToken
      })
    );
    
    const authenticate = async () => {
      if(isLoading === false){
        setIsLoading(true);

        if(accessToken === ""){
          if(!await doLogin(linkToken,false)){
            return false;
          }
        }

        const fetchData:FetchData = await authenticateToken();
        if(fetchData["status"] === "success"){
          store.dispatch(
            ClientDetailsUpdate({
              portfolio: fetchData["data"]["portfolio"],
              client: fetchData["data"]["client"],
              is_password: fetchData["data"]["is_password"],
              expire_date: fetchData["data"]["expire_date"],
            })
          );

          setIsPassword(fetchData["data"]["is_password"]);
          setAuthenticateData(fetchData);
        }else if(fetchData["status"] === "error" && fetchData["error"] === "token_expired") {
          if(await doLogin(linkToken,false)){
            authenticate();
          }
        }else{
          setAuthenticateData(fetchData);
        }
      }
    }

    authenticate();
  }

  return (
    <>
      {authenticateData === undefined?(
        <div className="min-h-screen w-full bg-gray-400">
          <Loader />
        </div>
      ):authenticateData !== undefined && authenticateData["status"] === "success"?(
        isPassword?(
          <CheckPassword setIsPassword={setIsPassword} />
        ):(
          children
        )
      ):authenticateData !== undefined && authenticateData["status"] === "error" && authenticateData["error"] === 404?(
        <div className="min-h-screen w-full bg-gray-400">
          <ClientError />
        </div>
      ):authenticateData !== undefined && authenticateData["status"] === "error" && authenticateData["message"] === "expired"?(
        <div className="min-h-screen w-full bg-gray-400">
          <ErrorExpired />
        </div>
      ):authenticateData !== undefined && authenticateData["status"] === "error" && authenticateData["message"] === "revok"?(
        <div className="min-h-screen w-full bg-gray-400">
          <ErrorRevok />
        </div>
      ):(
        <div className="min-h-screen w-full bg-gray-400">
          {/* <Error500 /> */}
        </div>
      )}
    </>
  );
};

export default ClientAuthenticate;